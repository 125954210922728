import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import SyllablesTask from "../../../../../components/syllables-task"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "hoelderlins-reisen",
          chapterId: "02-reiseerinnerung",
          taskId: "silben",
        })
        navigate("/kurse/hoelderlins-reisen/02-reiseerinnerung/silben/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Das Spiel mit den Silben" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Das Spiel mit den Silben
            </Heading>
          </Stack>
          <Paragraph>
            Das Spiel mit den Silben geht noch weiter. Zähle die Silben in den
            Zeilen.
          </Paragraph>
          <Note variant="task">
            <>
              Zähle die Silben der Zeilen. Hölderlin hat seine Silben beim
              Schreiben direkt auf den Tisch geklopft. Das kannst du auch
              machen, indem du die Worte laut vor dich hinsagst und bei jeder
              Silbe den{" "}
              <span
                role="img"
                aria-label="Auf den Tisch klopfende Faust nach links"
              >
                🤛
              </span>
              -Button drückst.
            </>
          </Note>
          <Stack>
            <Stack space={3}>
              <SyllablesTask name="q-01">
                Lange lieb ich dich schon, möchte dich, mir zur Lust,
              </SyllablesTask>
              <SyllablesTask name="q-02">
                Mutter nennen und dir schenken ein kunstlos Lied,
              </SyllablesTask>
              <SyllablesTask name="q-03">Du der Vaterlandsstädte</SyllablesTask>
              <SyllablesTask name="q-04">
                Ländlichschönste, so viel ich sah.
              </SyllablesTask>
            </Stack>
            <Stack space={3}>
              <SyllablesTask name="q-05">
                Wie der Vogel des Walds über die Gipfel fliegt,
              </SyllablesTask>
              <SyllablesTask name="q-06">
                Schwingt sich über den Strom, wo er vorbei dir glänzt
              </SyllablesTask>
              <SyllablesTask name="q-07">
                Leicht und kräftig die Brücke
              </SyllablesTask>
              <SyllablesTask name="q-08">
                Die von Wagen und Menschen tönt.
              </SyllablesTask>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
